@import "./prismjs";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
}

a {
  text-decoration: none;
}

:not(pre) > code {
  font-family: Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace;
  background-color: #eeeeee;
  padding: 2px 4px;
  direction: ltr;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  font-size: 14px;
}

.css-1jgv6z-ToastContainer {
  top: 110px !important;
  .css-9z2we-ToastElement {
    height: 75px !important;
  }
  .react-toast-notifications__toast__content {
    font-size: 12px;
    font-family: Roboto;
  }
  .css-80a6v4-Icon {
    top: 40%;
  }
}

.css-3y3n1b {
  box-sizing: border-box !important;
  max-height: 100% !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  position: fixed !important;
  z-index: 1000 !important;
  top: 100px !important;
  right: 0px !important;
}

//breadcrumbs

.MuiBreadcrumbs-ol {
  text-decoration: none !important;
}

.MuiPaper-elevation8 {
  box-shadow: 0px 1px 2px -11px rgba(0, 0, 0, 0.02),
    0px 8px 4px 1px rgba(0, 0, 0, 0), 0px 3px 14px 2px rgba(0, 0, 0, 0.08) !important;
}
._loading_overlay_overlay {
  background: rgba(236, 240, 241, 0.7) !important;
}

.online-staff .MuiBadge-anchorOriginTopRightRectangle {
  background-color: white;
  color: #3c1a5b;
}
.notification-time {
  margin-bottom: 14px !important;
  text-align: right;
}

@media only screen and (max-width: 700px) {
  .counsellor_select .MuiInputBase-input {
    width: unset;
  }
  .student-profile-card {
    overflow-x: scroll !important;
  }
  .counsellor-profile-card {
    overflow-x: scroll !important;
  }
  .notification-time {
    margin-bottom: 0px !important;
  }
  .activtiy-details {
    font-size: 10px !important;
  }
  // .counsellor-buttons .MuiButton-label {
  //   font-size: 1.3rem;
  // }
}
.filter-form-control {
  margin-left: unset !important;
}
.notification-title {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: bold;
}
.notification-description {
  font-family: "IBM Plex Sans", sans-serif;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #3c1a5b !important;
  border: 1px double #3c1a5b !important;
  color: #fff !important;
}
.CalendarDay__selected_span {
  background: #6d4b8c !important;
  border: 1px double #6d4b8c !important;
  color: #fff !important;
}
